import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./App.css";

import Article from "./Article";

function App() {
  var [articles, setArticles] = useState(2);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   }
  // })

  // function handleScroll() {
  //   let el = [...Array(articles)].map((e, index) => {
  //     if(document.querySelector(".article-" + index)) {
  //       return {
  //         value: (document.querySelector(".article-" + index).getBoundingClientRect().top 
  //         - document.body.getBoundingClientRect().top - 200),
  //         title: index
  //       }
  //     }
  //   })

  //   let last = el.filter(e => {
  //     return e.value < window.top.pageYOffset;
  //   })

  //   console.log(last)
  //   // if(last[last.length - 1] != undefined) {
  //   //   console.log(last[last.length - 1])
  //   //   // < window.location.href.split("-")[1]
  //   //   window.history.pushState('page', 'Title', '/article-' + last[last.length - 1].title);
  //   // }
  // }



  function fetchArticle() {
  	articles++

  	setTimeout(() => {
  		setArticles(articles)
  	}, 1000)
  }

  return (
    <div className="App">
      <InfiniteScroll
        dataLength={articles}
        next={fetchArticle}
        hasMore={true}
        loader={<h4 >Loading...</h4>}
      >
        {[...Array(articles)].map((x, i) => 
        <div className={"article-" + i}>
    		  <Article  title={i} key={i} />
        </div>
  		)}
      </InfiniteScroll>
    </div>
  );
}

export default App;

