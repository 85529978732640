import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import "./App.css";

function Article({ title }) {
  var [location, setLocation] = useState("");

  useEffect(() => {
    // setTimeout(() => {
    if (!location) {
      window.history.pushState("page", "Title", "/article-" + title);

      setLocation(window.location.href);
    }
    // }, 1000)
  });

  return (
    <div className="Article">
      <h1>Article {title}</h1>
      <p>
        Apple blocked Facebook from informing users that Apple would collect 30
        percent of in-app purchases made through a planned new feature, Facebook
        tells Reuters. Apple said the update violated an App Store rule that
        doesn’t let developers show “irrelevant” information to users.
      </p>
      <p>
        The feature lets Facebook users buy tickets for online events directly
        through the app. Apple’s rules say that purchases of digital content
        have to use the App Store’s payments system, giving Apple 30 percent of
        the total. Facebook says it asked Apple to waive this fee so that all of
        the revenue could go to event organizers, but Apple refused. The feature
        is now available, but without the message about Apple’s 30-percent cut.
      </p>
      <h2>{location}</h2>
      <div className="Banner">
        <Iframe
          url="https://secure.adnxs.com/tt?id=20079760"
          width="300px"
          height="250px"
          id="myId"
          className="Box"
          display="initial"
          position="relative"
        />
      </div>
    </div>
  );
}

export default Article;
